import React from "react";
import { EMAILS } from "utils/constants";

export default function NotFoundPage() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center">
      <h1 className="text-3xl font-bold">
        Oops, we can&apos;t find that page.
      </h1>
      <p className="mt-4">
        If you think you are reaching this page in error, please reach out to{" "}
        {EMAILS.CONTACT}
      </p>
    </div>
  );
}
